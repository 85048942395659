import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";
import { TagFilterAPIItem } from "../../components/Filters/TagsFilter/types";
import { OwnerOptionsAPIItem } from "../../components/Filters/OwnerFilter/types";
import { ItemReq } from "../../components/AddItem/AddItemForm/type";

export interface itemTypesAPIItem {
  key: number;
  value: string;
}

export interface IStatusFilterAPIParams {
  containerIds: string[];
}

export interface OwnerOptionsAPIParams {
  containerId: string;
  search: string;
}

export interface IStatusOptions {
  key: number;
  value: string;
  colorCode: string;
  isDefault: boolean;
}

export const getLabelOptions = (
  searchString: string,
  accessToken: any
): Promise<{ data: TagFilterAPIItem[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/labels`,
    headers: { authorization: bearer },
    params: { SearchText: searchString },
  });
};

export const getStatusOptions = (
  requestBody: IStatusFilterAPIParams,
  accessToken: any
): Promise<{ data: TagFilterAPIItem[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "POST",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/filters/item-statuses`,
    headers: { authorization: bearer },
    data: requestBody,
  });
};

export const getAllOwnersOptions = (
  searchString: string,
  accessToken: any
): Promise<{ data: OwnerOptionsAPIItem[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/users`,
    headers: { authorization: bearer },
    params: { SearchText: searchString },
  });
};

export const getOwnersOptions = (
  requestBody: OwnerOptionsAPIParams,
  accessToken: any
): Promise<{ data: OwnerOptionsAPIItem[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "POST",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/users/container-User`,
    headers: { authorization: bearer },
    data: requestBody,
  });
};

export const getItemTypesOptions = (
  accessToken: any
): Promise<{ data: itemTypesAPIItem[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/itemTypes`,
    headers: { authorization: bearer },
  });
};

export const getStatusOptionsByType = (
  itemTypeId: string,
  accessToken: any
): Promise<{ data: IStatusOptions[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/itemTypes/${itemTypeId}/status`,
    headers: { authorization: bearer },
  });
};

export const getImpactOptions = (
  itemTypeId: string,
  accessToken: any
): Promise<{ data: TagFilterAPIItem[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/itemTypes/${itemTypeId}/impact`,
    headers: { authorization: bearer },
  });
};

export const getPriorityOptions = (
  itemTypeId: string,
  accessToken: any
): Promise<{ data: TagFilterAPIItem[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/itemTypes/${itemTypeId}/priority`,
    headers: { authorization: bearer },
  });
};

export const getProbabilityOptions = (
  itemTypeId: string,
  accessToken: any
): Promise<{ data: TagFilterAPIItem[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/itemTypes/${itemTypeId}/probability`,
    headers: { authorization: bearer },
  });
};

export const addRaidItem = async (
  item: ItemReq,
  accessToken: string
): Promise<{ data: any }> => {
  const bearer = `Bearer ${accessToken}`;
  const formData = new FormData();
  formData.set("Title", item.title);
  formData.set(
    "TargetDate",
    new Date(item.targetDate).toString().substring(0, 16)
  );
  formData.set("ItemTypeId", item.itemTypeId.toString());
  formData.set("ItemType", item.itemType);
  formData.set("ItemStatusId", item.itemStatusId.toString());
  formData.set("ItemStatus", item.itemStatus.toString());
  formData.set("ContainerId", item.containerId.toString());
  formData.set("Container", item.container.toString());
  formData.set("OwnerId", item.ownerId.toString());
  formData.set("Owner", item.owner);
  item.labels?.forEach((label, i) => {
    label.labelId &&
      formData.set(`Labels[${i}].labelId`, label.labelId.toString());
    formData.set(`Labels[${i}].name`, label.name);
  });
  formData.set("ItemImpactId", item.itemImpactId?.toString() ?? "");
  formData.set("ItemImpact", item.itemImpact?.toString() ?? "");
  formData.set("ItemPriorityId", item.itemPriorityId?.toString() ?? "");
  formData.set("ItemPriority", item.itemPriority?.toString() ?? "");
  formData.set("ItemProbabilityId", item.itemProbabilityId?.toString() ?? "");
  formData.set("ItemProbability", item.itemProbability?.toString() ?? "");
  formData.set("Description", item.description?.toString() ?? "");
  formData.set("MitigationPlan", item.mitigationPlan?.toString() ?? "");

  item.itemComments?.forEach((comment, i) => {
    formData.set(`ItemComments[${i}].comment`, comment.comment.toString());
    comment.mentions.forEach((mention, j) => {
      formData.set(
        `ItemComments[${i}].mentions[${j}].userId`,
        mention.userId.toString()
      );
    });
  });

  item.itemAttachments?.forEach((file: any) => {
    formData.append("ItemAttachements", file);
  });

  return axios({
    method: "post",
    maxBodyLength: Infinity,
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/items`,
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: bearer,
    },
    data: formData,
  });
};

export const getDefaultProjectService = async (
  accessToken: any,
  params: { username: string }
) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/user-preferences`,
    headers: { authorization: bearer },
    params,
  });
};

